<template>
    <div class="row--header" v-if="isDesktop">
      <div class="row__group row__group--check">
        <ElementBadge  color=" "/>
      </div>

      <div class="row__group row__group--secondary" >
        <div class="row__item">
          <div class="row__label">
            <a href="#">
              Date From
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              Date To
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              Hours
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              Meeting
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              Location
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              CropLife Reponsable
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ElementBadge from '@elements/ElementBadge'

export default {
  name: 'CalendarTableHeader',
  components: {
    ElementBadge
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    isDesktop () {
      return this.device === 'desktop'
    }
  }

}
</script>

<style lang="scss">

</style>
