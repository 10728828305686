<template>
  <ContainerHub class="hub-events-table">

    <!-- Header Slot -->
    <template slot="header">
      <HubHeader
        :titleTag="'h2'"
        :title="title"
      />
    </template>

    <!-- Body Slot -->
    <template slot="body">
      <ElementTable>
        <template slot="header">
          <CalendarTableHeader />
        </template>
        <template slot="body">
          <CalendarTableBody :content="item" :key="item.guid" v-for="item in items" />
        </template>
      </ElementTable>
    </template>

    <!-- Footer Slot -->
    <template slot="footer" v-if="buttonCaption">
      <BaseButton
        :icon="'more'"
        :icon-position="'right'"
        :color="'primary'"
        v-if="showLoadMore"
        @click.native="handleClick">
        {{ buttonCaption }}
      </BaseButton>
    </template>
  </ContainerHub>
</template>

<script>
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import ElementTable from '@elements/ElementTable'
import CalendarTableHeader from '@blocks/calendar/CalendarTableHeader'
import CalendarTableBody from '@blocks/calendar/CalendarTableBody'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HubEventsList',
  props: {
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    buttonCaption: {
      type: String,
      required: false
    }
  },
  components: {
    BaseButton,
    HubHeader,
    ContainerHub,
    ElementTable,
    CalendarTableHeader,
    CalendarTableBody
  },
  computed: {
    ...mapGetters({
      pastOffset: 'events/getPastOffset',
      pastTotal: 'events/getPastTotal',
      upcomingOffset: 'events/getUpcomingOffset',
      upcomingTotal: 'events/getUpcomingTotal'
    }),
    showLoadMore () {
      if (this.name === 'past-events') {
        return this.pastOffset < this.pastTotal
      }
      return this.upcomingOffset < this.upcomingTotal
    }
  },
  methods: {
    ...mapActions({
      fetchGetAddPastEvents: 'page/fetchGetAddPastEvents',
      fetchGetAddUpcomingEvents: 'page/fetchGetAddUpcomingEvents'
    }),
    handleClick () {
      if (this.name === 'past-events') this.fetchGetAddPastEvents()
      else if (this.name === 'upcoming-events') this.fetchGetAddUpcomingEvents()
    }
  }
}
</script>

<style lang="scss">
.hub-events-table{
  @media print {
    .row__group--secondary .row__item:first-child{
      border-left: 1px solid colors("border");
    }
  }

  margin-bottom: 2rem;

  // Container Hub

  &.container-hub {
    padding: 0;
  }

  // Body

  &__body {
    @include media("small-medium") {
      flex-direction: column
    }

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;

    margin-left: - layout("spacing")/2;
    margin-right: - layout("spacing")/2;
    margin-bottom: 2rem;

    .card-event{
      @include media("small") {
        width: 100%;
      }
      @include media("medium"){
        min-width: calc((100% - #{layout("spacing")} * 2) / 2);
      }
      @include media("small-medium"){
        margin-bottom: layout("spacing")/2;
      }
      @include media("large-only"){
        min-width: calc((100% - #{layout("spacing")} * 2) / 2);
        &:not(:nth-of-type(2n)) {
          margin-right: layout("spacing")/2;
        }
      }
      @include media("xlarge"){
        min-width: calc((100% - #{layout("spacing")} * 3) / 3);
        &:not(:nth-of-type(3n)) {
          margin-right: layout("spacing")/2;
        }
      }

      width: auto;
      max-width: 100%;
      flex: 1 1 auto;
      margin-left: layout("spacing")/2;
      margin-right: layout("spacing")/2;
      margin-bottom: layout("spacing");
    }
  }

  // Table

  .row {
    @include media('small-medium') {
      &__group {
        a {
          // border:1px solid red
        }
        &--main {
          .row__info {
          justify-content: space-between;
          }
        }
      }
    }
    // &__item {
    //   word-wrap: break-word;
    //   hyphens: auto;
    //     border:1px solid red;

    // }
    &__info{

      a {
        // border:1px solid red;
      }
    }
  }

  .row--header {
    .badge {
      &__color {
        margin: 0;
      }

    }
  }
}
</style>
