import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      categories: 'filters/getCategories'
    }),
    legendItems: function () {
      if (!this.categories.length) {
        return []
      }

      let types = this.categories.find((item) => {
        return item.guid === 'meetingType' || item.guid === 'type_id'
      })

      if (types) {
        return types.items
      }

      return []
    }
  }
}
