<template>
  <div class="row">
    <div class="row__group row__group--check">
      <ElementBadge :color="content.type.guid" @click.prevent.stop="handleClick" />
    </div>

    <div class="row__group row__group--main" v-if="!isDesktop">
      <div class="row__label">Date From</div>
      <div class="row__info">
        {{ content.schedule.start.date }}
        <a href="javascript:void(0);" class="row__item__trigger" @click.prevent.stop="handleClick" >
          <BaseIcon :type="icon" />
        </a>
      </div>
    </div>

    <div class="row__group row__group--secondary" v-show="showInfos">
      <div class="row__item" v-if="isDesktop">
        <div class="row__label">Date To</div>
        <div class="row__info">{{ content.schedule.start.date }}</div>
      </div>
      <div class="row__item" >
        <div class="row__label">Date To</div>
        <div class="row__info">{{ content.schedule.end.date }}</div>
      </div>
      <div class="row__item">
        <div class="row__label">Hours</div>
        <div class="row__info">{{ content.schedule.start.time }} - {{ content.schedule.end.time }}</div>
      </div>
      <div class="row__item">
        <div class="row__label">Meeting</div>
        <div class="row__info">

          <BaseButton
            clear
            :color="'primary'"
            :url="content.url"
            :target="'_blank'"
            v-if="content.url">
            {{ content.title }}
          </BaseButton>
          <BaseButton
            clear
            :color="'primary'"
            :route="route"
            v-else>
            {{ content.title }}
          </BaseButton>
        </div>
      </div>
      <div class="row__item" v-if="isDesktop">
        <div class="row__label">Location</div>
        <div class="row__info" v-html="content.location"></div>
      </div>

      <div class="row__item" v-if="isDesktop">
        <div class="row__label">CropLife Responsible</div>
        <div class="row__info">{{ content.responsible || '' }}</div>
      </div>
            <div class="row__item" v-else-if="content.responsible">
        <div class="row__label">CropLife Responsible</div>
        <div class="row__info">{{ content.responsible || '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementBadge from '@elements/ElementBadge'
import ElementRow from '@elements/ElementRow'
import BaseIcon from '@base/BaseIcon'
import BaseButton from '@base/BaseButton'

export default {
  name: 'CalendarTableBody',
  extends: ElementRow,
  components: {
    ElementBadge,
    BaseIcon,
    BaseButton
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showInfos: true
    }
  },
  computed: {
    isDesktop () {
      return this.device === 'desktop'
    },
    route () {
      return `/calendar/event/${this.content.guid}`
    }
  },
  watch: {
    isDesktop () {
      this.showInfos = true
    }
  },
  methods: {
    handleClick () {
      this.showInfos = !this.showInfos
    }
  }
}
</script>
