<template>
  <div class="calendar-legend">
    <span class="calendar-legend__title" v-if="title">{{ title }} :</span>
    <span class="calendar-legend__badges">
      <ElementBadge
        :text="item.label"
        :colorHex="item.color"
        :showText="true"
        :key="item.guid"
        v-for="item in items"
      />
    </span>
  </div>
</template>

<script>
import ElementBadge from '@elements/ElementBadge'
export default {
  name: 'CalendarLegend',
  props: {
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  components: {
    ElementBadge
  }
}
</script>

<style lang="scss">
.calendar-legend {
      @media print {
display: none;
    }
  display: flex;
  margin-top: 3rem;

  &__title {
    white-space: nowrap;
  }

  &__badges {
    @include media('small-medium') {
      display: flex;
      flex-direction: column
    }
    .badge {
      margin-left: 1.5rem;
    }
  }

}
</style>
